export var Backend = {
    Decap: 'decap',
    Drupal: 'drupal'
};
export var BlockTeaserListLayout = {
    Carousel: 'CAROUSEL',
    Grid: 'GRID'
};
export var CtaIconPosition = {
    After: 'AFTER',
    Before: 'BEFORE'
};
export var CtaIconType = {
    Arrow: 'ARROW',
    None: 'NONE'
};
export var ImagePosition = {
    Left: 'left',
    Right: 'right'
};
export var InfoGridIcon = {
    Email: 'EMAIL',
    LifeRing: 'LIFE_RING',
    None: 'NONE',
    Phone: 'PHONE'
};
export var Locale = {
    De: 'de',
    En: 'en'
};
export var CreateSubmissionMutation = "CreateSubmissionMutation:b585180e5579cb960b45197ad15ac71e5dc70d92bc935df5e2c92574edcd8d37";
export var ContentHubQuery = "ContentHubQuery:ed59c9772fd89f13bb810db47014eb33fede4a2d8e30b4f35cc522864af5f242";
export var FrameQuery = "FrameQuery:0947676cc7f3b3a8580100f8e3321d4796c6e4fc744ed6becc287c6d6c26f763";
export var HomePageQuery = "HomePageQuery:0ed889027f745040e1b75e2977f4fa4f46d636283a2c1952353a733e7cb88d8f";
export var ListPagesQuery = "ListPagesQuery:1f7e06f1abff052f072012075876848f4df447641e84c1cb9ab5809ae923108a";
export var NotFoundPageQuery = "NotFoundPageQuery:d4cf0f0d01da41439b72facab226e2cd90141652c0bf4332aba707a5a4f8363b";
export var PreviewDecapPageQuery = "PreviewDecapPageQuery:a6bcc1ce842918f59e8e1ab9cf1ef84637592ac3a12e7266d61002bec2a6e22b";
export var PreviewDrupalPageQuery = "PreviewDrupalPageQuery:a966c5726be29b4d5944fe8a1b248408e98d408e99810ca14bd60edfa324edf0";
export var TeaserListQuery = "TeaserListQuery:73d1ce59861405bf81ce1cdbff5321cc1baf433aa8a928bc354b9669f7abee26";
export var ViewPageQuery = "ViewPageQuery:1f11647cff081629248bd7f5dd00a0da45a2ebb1f4f21e573ab9e333ae89baf5";
